import React from "react";
import { Suspense } from "react";


const LandingLoading = (Component) => (props) => {
  return (
    <Suspense
      fallback={
        <div className="preloader">
          <span className="loader"></span>
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default LandingLoading;
