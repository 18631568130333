import logo from "./logo.svg";
// import "./App.css";
import Router from "./routes";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useRef } from "react";

function App() {
  const tawkMessengerRef = useRef();

  return (
    <HelmetProvider>
      <BrowserRouter>
        <TawkMessengerReact
          propertyId="65b770ba8d261e1b5f590fc5"
          widgetId="1hla90mls"
          ref={tawkMessengerRef}
        />
        <Router />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
