import { useRoutes } from "react-router-dom";


import main from "./main";


const Router = () => {
  return useRoutes([...main]);
};

export default Router;
