import { lazy } from "react";
import { Navigate } from "react-router";
// import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
// import MainLayout from "../layouts/main";
import LandingLoading from "./LandingLoading";
// const NotFound = LandingLoading(lazy(() => import("./Page404")));
const LandingPageMain = LandingLoading(lazy(() => import("src/landing/index")));
const LandingPage = LandingLoading(lazy(() => import("src/landing/Content")));
const SecondLanding = LandingLoading(
  lazy(() => import("src/landing/second-landing/Index"))
);
const Market = LandingLoading(lazy(() => import("src/landing/market/index")));
const MarketDetails = LandingLoading(
  lazy(() => import("src/landing/marketDetails/index"))
);
const About = LandingLoading(lazy(() => import("src/landing/about/index")));

const Team = LandingLoading(lazy(() => import("src/landing/team/index")));
const TeamDetails = LandingLoading(
  lazy(() => import("src/landing/team-details/index"))
);
const Blog = LandingLoading(lazy(() => import("src/landing/blog/index")));
const BlogDetails = LandingLoading(
  lazy(() => import("src/landing/blog-details/index"))
);
const Careers = LandingLoading(lazy(() => import("src/landing/career/index")));
const Contact = LandingLoading(lazy(() => import("src/landing/contact/index")));
const Education = LandingLoading(
  lazy(() => import("src/landing/education/index"))
);
const LegalDoc = LandingLoading(
  lazy(() => import("src/landing/legal-documents/index"))
);
const Customers = LandingLoading(
  lazy(() => import("src/landing/customers/index"))
);
const RoadMap = LandingLoading(lazy(() => import("src/landing/roadmap/index")));

const Support = LandingLoading(lazy(() => import("src/landing/support/index")));
const TermsConditions = LandingLoading(
  lazy(() => import("src/landing/terms-conditions/index"))
);

const PrivacyPolicy = LandingLoading(
  lazy(() => import("src/landing/privacy-policy/index"))
);

const Error = LandingLoading(
  lazy(() => import("src/landing/error/index"))
);

const main = [
  {
    path: "/",
    element: <LandingPageMain />,
    children: [
      { element: <LandingPage />, index: true },
      { path: "landing-2", element: <SecondLanding /> },
      { path: "market", element: <Market /> },
      { path: "market-details", element: <MarketDetails /> },
      { path: "about", element: <About /> },
      { path: "team", element: <Team /> },
      { path: "team-details", element: <TeamDetails /> },
      { path: "products", element: <Blog /> },
      { path: "blog-details", element: <BlogDetails /> },
      { path: "careers", element: <Careers /> },
      { path: "contact", element: <Contact /> },
      { path: "education", element: <Education /> },
      { path: "legal-doc", element: <LegalDoc /> },
      { path: "customers", element: <Customers /> },
      { path: "roadmap", element: <RoadMap /> },
      { path: "support", element: <Support /> },
      { path: "terms-condition", element: <TermsConditions /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
    ],
  },

  // {
  //   path: "*",
  //   element: <LogoOnlyLayout />,
  //   children: [
  //     { path: "landing", element: <Error /> },
  //     { path: "404", element: <NotFound /> },
  //     { path: "*", element: <Navigate to="/404" replace /> },
  //   ],
  // },
  {
    path: "*",
    children: [
      { path: "*", element: <Navigate to="/404" replace /> },
      { path: "404", element: <Error /> },
      
    ],
  },
];

export default main;
